import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import * as ui from '../../../ui'
import { colors, size } from '../../../style';
import util from '../../../util';
import { useHistory } from 'react-router-dom';

const InputWrapper = styled(ui.InputWrapper)`
    margin-bottom:15px;
`

const Wrapper = styled.div`
    padding:30px;
    padding-top:0px;
    flex:1;
    margin-top:30px;
`

const ImageView = styled.div`
    margin-top:20px;
    width:80px;
    height:80px;
    img{
        width:100%;
        height:100%;
        object-fit: cover;
        background-color:${colors.disable};
    }

    a{
        display:block;
        border:2px solid ${colors.primary};
    }

    ${({ disabled }) => disabled && `
        a{
            border:none;
        }
        pointer-events:none;
       
    `}
`

const EcLinkGrid = styled.div`
    display: grid;
    grid-column-gap: 60px;
    width:100%;
    grid-template-columns: 1fr 1fr;
`

class ECLinkFormModel {

    constructor(project, [loading, setLoading], [dataList, setDataList]) {
        this.project = project
        this.loading = loading
        this.setLoading = setLoading
        this.dataList = dataList
        this.setDataList = setDataList
    }

    setLinkUrl = (val, index) => {
        const dataList = this.dataList.slice()
        dataList[index]["linkUrl"] = val
        this.setDataList(dataList)

        setTimeout(this.fixDataList, 100)
    }

    setImageUrl = (val, index) => {
        const dataList = this.dataList.slice()
        dataList[index]["imageUrl"] = val
        this.setDataList(dataList)

        setTimeout(this.fixDataList, 100)
    }

    fixDataList = () => {
        const dataList = this.dataList.slice()
        const trimedDataList = []

        dataList.forEach(data => {
            if (data.imageUrl.length > 0 || data.linkUrl.length > 0) {
                trimedDataList.push(data)
            }
        });

        if (trimedDataList.length === 0) {
            trimedDataList.push({
                linkUrl: "",
                imageUrl: "",
            })
        }

        const lastData = trimedDataList[trimedDataList.length - 1]
        if (lastData.imageUrl.length > 0 && lastData.linkUrl.length > 0) {
            trimedDataList.push({
                linkUrl: "",
                imageUrl: "",
            })
        }

        this.setDataList(trimedDataList)
    }

    data = (index) => {
        return this.dataList[index]
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter" && e.keyCode === 13) {
            this.trySubmit()
        }
    }

    trySubmit = () => {
        const _dataList = this.dataList.slice().filter((data) => {
            return data.imageUrl.length > 0 || data.linkUrl.length > 0
        })

        store.updateProjectLink(this.project.id, _dataList).then(results => {
            console.log("results", results)
        })
    }

}

const ECLinkForm = ({ project }) => {

    const viewModel = new ECLinkFormModel(project,
        useState(false),
        useState(project.linkList)
    )
    useEffect(viewModel.fixDataList, [])

    const { dataList } = viewModel

    return <Wrapper>
        <ui.PageTitle>ECリンク</ui.PageTitle>
        <EcLinkGrid>
            {dataList.map((data, index) => {
                return <ECLinkRow viewModel={viewModel} data={data} index={index} key={index} />
            })}
        </EcLinkGrid>
    </Wrapper>
}

const RowWrapper = styled.div`
    display:flex;
    width:100%;
`

const LeftContents = styled.div`
    flex:1;
    margin-right:20px;
`

const ECLinkRow = ({ viewModel, data, index }) => {

    const { loading } = viewModel
    const { imageUrl, linkUrl } = data

    return <RowWrapper>
        <LeftContents>
            <InputWrapper>
                <ui.InputInfo>
                    <ui.InputLabel>画像URL</ui.InputLabel>
                </ui.InputInfo>
                <ui.Input
                    type="text"
                    valid={imageUrl.length > 0}
                    value={imageUrl}
                    disable={loading}
                    onKeyDown={viewModel.handleKeyDown}
                    onChange={(e) => {
                        viewModel.setImageUrl(e.target.value, index)
                    }}
                />
            </InputWrapper>
            <InputWrapper>
                <ui.InputInfo>
                    <ui.InputLabel>リンクURL</ui.InputLabel>
                </ui.InputInfo>
                <ui.Input
                    type="text"
                    valid={linkUrl.length > 0}
                    value={linkUrl}
                    disable={loading}
                    onKeyDown={viewModel.handleKeyDown}
                    onChange={(e) => {
                        viewModel.setLinkUrl(e.target.value, index)
                    }}
                />
            </InputWrapper>
        </LeftContents>
        <ImageView disabled={linkUrl.length === 0}>
            <a href={linkUrl} target="_blank">
                <img src={imageUrl} />
            </a>
        </ImageView>
    </RowWrapper>
}

export default ECLinkForm